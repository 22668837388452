@import 'vendors/swiper.min';
@import 'vendors/fancybox.min';

@import 'base/app';
@import 'components/category-card';

@import 'components/form-signup';
@import 'components/form-technical';
@import 'components/form-advice';

@import 'components/content/text';


.index-title{
    display: flex;
    justify-content: center;
    text-align: center;
}

.indexslider{
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    // &>.swiper{
    //     height: 550px;
    //     @include _1200{
    //         height: 470px;
    //     }
    //     @include _768{
    //         height: unset;
    //     }
    // }
    &-slide{
        height: auto !important;
        &__bg{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__content{
            max-width: 1455px;
            margin: 0 auto;
            &-inner{
                max-width: 50%;
                padding: 72px 0 30px;
                @include _1200{
                    padding: 30px 15px 0;
                }
                @include _992{
                    max-width: 576px;
                }
            }
        }
        &__title{
            font-weight: 900;
            font-size: 60px;
            line-height: 60px;
            color: $text-clr;
            padding: 0 0 30px;
            @include _1200{
                font-size: 34px;
                line-height: 38px;
                padding: 0 0 18px;
            }
        }
        &__text{
            .content-text a,
            .content-text li,
            .content-text p {
                font-size: 22px;
                line-height: 28px;
            }
            .content-text ul li::before{
                top: 11px;
            }
        }
        &__link{
            display: inline-block;
            padding: 38px 0 0;
            @include _1200{
                padding: 30px 0 0;
                .btn{
                    padding: 10px 45px;
                }
            }
        }
    }
    &-nav{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0 0;
        @include _1200{
            padding: 30px 0 0;
            .swiper-navigation{
                display: none;
            }
        }
        &__wrap{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}

.indexbest{
    margin: 20px 0 0;
    &-container{
        background-color: #FFFFFF;
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.09);
        border-radius: 8px;
        padding: 55px 0;
        @include _1200{
            padding: 30px;
        }
    }
    &-inner{
        max-width: 1460px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 50px;
        row-gap: 20px;
        @include _1200{
            grid-column-gap: 20px;
        }
        @include _768{
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 20px;
        }
        h1{
            grid-column: 1/-1;
            text-align: center;
        }
    }

    .indexterms-card{
        align-items: center;
        &__info{
            b{
                margin: 0;
            }
        }
    }
}

.indexcats{
    margin: 105px 0 0;
    padding: 105px 0 115px;
    background-color: #EFF4FA;
    @include _1200{
        padding: 50px 0;
        margin: 50px 0 0;
    }
    &-title{
        margin: 0 0 50px;
        @include _1200{
            margin: 0 0 25px;
        }
    }

    &-container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        @include _1200{
            grid-template-columns: repeat(2, 1fr);
            .category-card{
                &:nth-child(n+5){
                    display: none;
                }
            }
        }
        @include _576{
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &-catalog{
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
    &-btn{
        height: 60px;
        width: 20%;
        @include _1200{
            width: 23%;
            height: 55px;
        }
        @include _992{
            width: 60%;
            height: 45px;
        }
        @include _576{
            width: 100%;
        }
    }

    &-mobile{
        &__showmore{
            display: none;
            button{margin: 30px auto 0;}
            @include _1200{
                display: block;
            }
        }
    }
}

.indexsignup{
    position: relative;
    background-color: #EFF4FA;
    &::after{
        content: '';
        display: block;
        position: absolute;
        background-color: #fff;
        bottom: 0px;
        width: 100%;
        height: 30%;
    }
    .signup-form{
        position: relative;
        z-index: 1;
    }
}

.indexmybest{
    margin: 105px 0 0;
    @include _1200{
        margin: 50px 0 0;
    }
    &-title{
        margin: 0 0 48px;
        @include _1200{
            margin: 0 0 25px;
        }
    }
    &-container{
        max-width: 1100px;
        margin: 0 auto;
        @include _1200{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 20px;
            grid-row-gap: 20px;
        }
        @include _768{
            grid-template-columns: 1fr;
        }
        >div{
            &:not(:last-child){
                margin: 0 0 45px;
                @include _1200{
                    margin: 0;
                }
            }
        }
    }
}

.mybest-card{
    $this: &;
    background-color: #fff;
    box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 50px 60px;
    position: relative;
    &:last-child{
        #{$this}__arrow{
            display: none;
        }
    }
    @include _1200{
        padding: 16px 20px;
    }
    &__wrap{
        display: grid;
        grid-template-columns: 1.2fr 1fr;
        grid-column-gap: 50px;
        @include _1200{
            grid-template-columns: 1fr;
            grid-row-gap: 12px;
        }
    }
    &__arrow{
        position: absolute;
        top: 50%;
        right: calc(100% + 50px);
        @include _1200{
            display: none;
        }
        svg{
            width: 85px;
            height: 270px;
            fill: $main-clr;
        }
    }
    &__image{
        border-radius: 8px;
        overflow: hidden;
        img{
            width: 100%;
            height: 320px;
            object-fit: cover;
            @include _1200{
                height: 110px;
            }
        }
    }
    &__text{
        padding-right: 50px;
        @include _1200{
            padding: 0;
        }
    }
    &__title{
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        color: #121212;
        margin: 0 0 10px;
        @include _1200{
            font-size: 15px;
            line-height: 18px;
            margin: 0 0 5px;
        }
    }
    &__desc{
        font-size: 21px;
        line-height: 26px;
        color: #121212;
        @include _1200{
            font-size: 15px;
            line-height: 18px;
        }    
    }
    &:nth-child(even){
        #{$this}__arrow{
            right: unset;
            left: calc(100% + 50px);
            transform: scaleX(-1);
        }
        #{$this}__text{
            order: 1;
            @include _1200{
                order: 2;
            }
        }
        #{$this}__image{
            order: 2;
            @include _1200{
                order: 1;
            }
        }
    }
}

.indextechnical{
    margin: 80px 0 0;
    @include _1200{
        margin: 50px 0 0;
    }
}

.indexproduction{
    margin: 105px 0 0;
    @include _1200{
        margin: 50px 0 0;
    }
    &-title{
        margin: 0 0 40px;
        @include _1200{
            margin: 0 0 20px;
        }
    }
    &-container{
        max-width: 1455px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 85px;
        @include _1200{
            grid-column-gap: 30px;
        }
        @include _768{
            grid-template-columns: 1fr;
            grid-row-gap: 25px;
        }
    }
    &-video{
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius:8px;
        overflow: hidden;
    }
    &-info{
        padding: 55px 35px;
        @include _1200{
            padding: 25px 0;
        }
        @include _768{
            padding: 0;
        }
        &__title{
            font-weight: 700;
            font-size: 28px;
            line-height: 34px;
            color: $text-clr;
            margin: 0 0 10px;
            @include _1200{
                font-size: 15px;
                line-height: 18px;
                margin: 0 0 5px;
            }
        }
        &__desc{
            font-size: 21px;
            line-height: 26px;
            color: $text-clr;
            @include _1200{
                font-size: 15px;
                line-height: 18px;
            }
        }
    }
    &-cards{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 40px;
        margin: 40px 0 0;
        @include _1200{
            margin: 30px 0 0;
            grid-column-gap: 18px;
        }
    }
    &-cardsitem{
        text-align: center;
        padding: 30px 0;
        background: #FFFFFF;
        box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        @include _1200{
            padding: 15px 5px;
        }
        b{
            font-weight: 900;
            font-size: 66px;
            line-height: 125%;
            color: $text-clr;
            @include _1200{
                font-size: 28px;
                line-height: 34px;
            }
        }
        em{
            font-weight: 900;
            font-size: 36px;
            line-height: 125%;
            @include _1200{
                font-size: 15px;
                line-height: 18px;
            }
        }
        span{
            display: block;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: $text-clr;
            @include _1200{
                font-size: 10px;
                line-height: 14px;
            }
        }
    }
    &-slider{
        margin: 20px auto 0;
        overflow: hidden;
        @include _1200{
            margin: 30px auto 0;
        }
        .swiper{
            height: 210px;
        }
        &__slide{
            height: auto;
            a{
                display: block;
                &::before{
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    transition: background-color $tr-time;
                }
                &:hover{
                    &::before{
                        background-color: rgba(1, 36, 114, 0.4)
                    }
                }
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__nav{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            max-width: 1455px;
            margin: 0 auto 40px;
            padding-right: 35px;
            @include _1200{
                display: none;
            }
            .swiper-navigation__prev{
                margin-right: 20px;
            }
            .swiper-navigation__next{
                margin-left: 20px;
            }
        }
    }
    
}

.indexsupply{
    position: relative;
    margin: 105px 0 0;
    padding: 105px 0;
    overflow: hidden;
    background-color: #EFF4FA;
    @include _1200{
        margin: 50px 0 0;
        padding: 50px 0;
    }
    &-decor{
        display: block;
        border-radius: 50%;
        background-color: $main-clr;
        position: absolute;
        z-index: 1;
        &--1{
            left: -100px;
            bottom: 20%;
            width: 200px;
            height: 200px;
            @include _1200{ 
                left: -40px;
                top: 90px;
                bottom: unset;
                width: 100px;
                height: 100px;
            }
        }
        &--2{
            height: 415px;
            width: 415px;
            right: 5%;
            @include _1200{
                right: -300px;
                top: calc(50% - 200px);
            }
        }
    }
    &-wrap{
        position: relative;
        z-index: 3;
    }
    &-title{
        margin: 0 0 50px;
        @include _1200{
            margin: 0 0 25px;
        }
    }
    &-container{
        position: relative;
        z-index: 2;
        max-width: 1455px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1.1fr 1fr;
        grid-column-gap: 55px;
        @include _1200{
            grid-column-gap: 30px;
        }
        @include _992{
            grid-template-columns: 1fr;
            grid-row-gap: 30px;
        }
    }
    &-who{
        padding: 20px 0 15px;
        @include _576{
            padding: 0;
        }
        &__text{
            font-weight: 700;
            font-size: 28px;
            line-height: 36px;
            color: $text-clr;
            margin: 0 0 35px;
            @include _1200{
                display: none;
            }
        }
        &__wrap{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 30px;
            grid-row-gap: 30px;
            @include _1200{
                grid-column-gap: 15px;
                grid-row-gap: 15px;
            }
            @include _576{
                grid-template-columns: 1fr;
            }
        }
        &__card{
            background-color: #FFFFFF;
            box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            padding: 45px 30px;
            display: grid;
            grid-template-columns: 70px 1fr;
            align-items: center;
            @include _1200{
                padding: 25px 30px;
            }
            svg{
                width: 48px;
                height: 48px;
                fill: $main-clr;
            }
            span{
                display: block;
                font-weight: 600;
                font-size: 28px;
                line-height: 32px;
                color: $text-clr;
                @include _1200{
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
    }
    &-video{
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 8px;
        overflow: hidden;
        @include _576{
            height: 175px;
        }
    }
}

.indexterms{
    margin: 105px 0 0;
    @include _1200{
        margin: 50px 0 0;
    }
    &-title{
        margin: 0 0 10px;
    }
    &-info{
        position: relative;
        padding: 0 0 35px;
        max-width: 980px;
        margin: 0 auto;
        @include _1200{
            padding: 0 0 30px;
        }
        &__text{
            font-size: 21px;
            line-height: 26px;
            text-align: center;
            color: #121212;
            @include _1200{
                font-size: 15px;
                line-height: 18px;
            }
        }
        &__phones{
            padding: 15px 0 0;
            text-align: center;
            @include _1200{
                display: flex;
                justify-content: space-around;
                max-width: 320px;
                margin: 0 auto;
            }
        }
        &__phone{
            display: inline-block;
            font-weight: 700;
            font-size: 36px;
            line-height: 42px;
            color: #121212;
            @include _1200{
                font-size: 16px;
                line-height: 22px;
                margin: 0 !important;
            }
            &:not(:last-child){
                margin-right: 50px;
            }
            &:hover{
                color: $main-clr;
            }
        }
        &__arrow{
            position: absolute;
            @include _1200{
                display: none;
            }
            svg{
                height: 200px;
                width: 65px;
                fill: $main-clr;
            }
            &--1{
                top: -100px;
                left: 0;
                transform: rotate(320deg);
            }
            &--2{
                transform: scaleX(-1);
                right: -120px;
                top: 20px;
            }
        }
    }
    &-form{
        background-color: #012472;
        max-width: 980px;
        margin: 0 auto;
        border-radius: 8px;
        padding: 25px 15px 40px;
        @include _420{
            padding: 50px 20px;
        }
        &__title{
            text-align: center;
            font-weight: 600;
            font-size: 21px;
            line-height: 26px;
            color: #fff;
            padding: 0 0 20px;
            @include _1200{
                font-size: 18px;
                line-height: 22px;
            }
        }
        &__inner{
            max-width: 610px;
            margin: 0 auto;
            @include _420{
                .theme-policy{
                    text-align: center;
                }
            }
        }
        &__container{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 16px;
            grid-row-gap: 16px;
            .theme-input{
                margin: 0;
            }
            @include _420{
                grid-template-columns: 1fr;
            }
        }
    }
    &-cards{
        max-width: 1455px;
        margin: 75px auto 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 75px;
        @include _1200{
            grid-column-gap: 30px;
            margin: 30px auto 0;
        }
        @include _768{
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 25px;
        }
    }
    &-card{
        display: grid;
        grid-template-columns: 115px 1fr;
        @include _1200{
            grid-template-columns: 60px 1fr;
        }
        &__icon{
            &-wrap{
                width: 90px;
                height: 90px;
                background-color: #012472;
                border-radius: 50%;
                @include _1200{
                    width: 50px;
                    height: 50px;
                }
                svg{
                    width: 35px;
                    height: 35px;
                    fill: #fff;
                    @include _1200{
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
        &__info{
            color: #121212;
            b, h1{ 
                display: block;
                font-weight: 600;
                font-size: 24px;
                line-height: 28px;
                margin: 0 0 8px;
                @include _1200{
                    font-size: 15px;
                    line-height: 18px;
                }
            }
            span{
                font-size: 21px;
                line-height: 28px;
                @include _1200{
                    font-size: 15px;
                    line-height: 18px;
                }
                
            }
        }
    }
}

.indexcooperation{
    margin: 105px 0 0;
    @include _1200{
        margin: 50px 0 0;
    }
    &-title{
        margin: 0 0 60px;
        @include _1200{
            margin: 0 0 30px;
        }
    }
    &-container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        @include _768{
            grid-template-columns: repeat(2, 1fr);
        }
        @include _576{
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &-card{
        background-color: #FFFFFF;
        box-shadow: 3px 3px 24px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        padding: 48px 60px;
        display: grid;
        grid-template-columns: 140px 1fr;
        @include _1200{
            padding: 15px 20px;
            grid-template-columns: 55px 1fr;
        }
        &__icon{
            position: relative;
            width: 85px;
            height: 85px;
            background-color: #EFF4FA;
            border-radius: 50%;
            @include _1200{
                width: 35px;
                height: 35px;
            }
            span{
                font-weight: 900;
                font-size: 32px;
                line-height: 38px;
                color: #012472;
                position: absolute;
                top: 0;
                left: 0;
                @include _1200{
                    font-size: 12px;
                    line-height: 18px;
                }
            }
            svg{
                width: 48px;
                height: 48px;
                fill: $main-clr;
                position: absolute;
                bottom: 10px;
                right: 0;
                @include _1200{
                    width: 20px;
                    height: 20px;
                    bottom: 0;
                }
            }
        }
        &__name{
            p{
                margin: 0 0 10px;
                font-weight: 700;
                font-size: 28px;
                line-height: 32px;
                color: #121212;
                @include _1200{
                    font-size: 15px;
                    line-height: 18px;
                    margin: 0 0 5px;
                }
            }
            span{
                font-size: 21px;
                line-height: 26px;
                color: #121212;
                @include _1200{
                    font-size: 15px;
                    line-height: 18px;
                }
            }
        }
    }
}

.indexadvice{
    margin: 75px 0 0;
    position: relative;
    @include _1200{
        margin: 50px 0 0;
    }
    &::after{
        content: '';
        display: block;
        width: 100%;
        height: 50%;
        background-color: #EFF4FA;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: -1;
    }
}

.indexcontacts{
    padding: 105px 0 120px;
    background-color: #EFF4FA;
    @include _1200{
        padding: 50px 0;
    }
    &-title{
        margin: 0 0 50px;
        @include _1200{
            margin: 0 0 35px;
        }
    }
    &-container{
        max-width: 1450px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 0.70fr 1fr;
        grid-column-gap: 30px;
        @include _768{
            grid-template-columns: 1fr;
            grid-row-gap: 40px;
        }
    }
    &-content{
        padding: 0 0 40px;
        @include _768{
            text-align: center;
            padding: 0;
        }
    }
    &-map{
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        @include _768{
            height: 450px;
        }
        @include _576{
            height: 390px;
        }
    }
    &-logo{
        margin: 0 0 35px;
        svg{
            width: 400px;
            height: 55px;
            fill: $main-clr-alt;
            @include _1200{
                width: 225px;
                height: 32px;
            }
        }
    }
    &-group{
        &:not(:last-child){
            margin: 0 0 35px;
            @include _1200{
                margin: 0 0 20px;
            }
        }
        &__name{
            font-size: 21px;
            line-height: 26px;
            color: #121212;
            margin: 0 0 5px;
            @include _1200{
                font-size: 15px;
                line-height: 18px;
            }
        }
        &__value{
            display: block;
            font-weight: 700;
            font-size: 28px;
            line-height: 32px;
            color: #121212;
            @include _1200{
                font-size: 22px;
                line-height: 26px;
            }
            &:not(:last-child){
                margin: 0 0 5px;
            }
        }
        > a{
            &:hover{
                color: $main-clr;
            }
        }
        &__social{
            margin: 12px 0 0;
            .icon{
                &:not(:last-child){
                    margin-right: 9px;
                    @include _992{
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}