.signup-form{
    background-color: #00C1F5;
    padding: 70px 0;
    border-radius: 8px;
    overflow: hidden;
    @include _1200{
        padding: 50px 0;
    }
    &__wrap{
        position: relative;
        z-index: 5;
        max-width: 1455px;
        margin: 0 auto;
        padding-left: 14%;
        @include _1200{
            max-width: unset;
            padding: 0 20px;
        }
        @include _992{
            text-align: center;
        }
    }
    &__title{
        color: #FFFFFF;
        margin: 0 0 12px;
    }
    &__desc{
        font-weight: 600;
        font-size: 21px;
        line-height: 26px;
        color: #FFFFFF;
        margin: 0 0 30px;
        @include _992{
            font-size: 15px;
            line-height: 18px;
            margin: 0 0 25px;
        }
    }
    &__form{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 10px;
        .theme-input{
            margin: 0;
        }
        @include _992{
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 8px;
            max-width: 450px;
            margin: 0 auto;
        }
    }
    &__decor{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @include _1200{
            
        }
        > div{
            position: absolute;
            border-radius: 50%;
        }
        &--1{
            width: 650px;
            height: 650px;
            background-color: #012472;
            left: -17%;
            top: -80%;
            @include _1200{
                display: none;
            }
        }
        &--2{
            width: 135px;
            height: 135px;
            border: 15px solid #fff;
            bottom: 20%;
            left: 10%;
            @include _1200{
                display: none;
            }
        }
        &--3{
            width: 50px;
            height: 50px;
            background-color: #fff;   
            right: 17%;
            top: 15%;
            @include _1200{
                display: none;
            }
        }
        &--4{
            width: 250px;
            height: 250px;
            background-color: #012472;
            right: 3%;
            top: -40%;
            @include _1200{
                width: 200px;
                height: 200px;
                right: -20%;
                top: -20%;
            }
        }
        &--5{
            width: 305px;
            height: 305px;
            border: 15px solid #fff;
            right: -150px;
            top: -170px;
            @include _1200{
                height: 80px;
                width: 80px;
                border: 6px solid #fff;
                right: calc(50% - 40px);
                top: -10%;
            }
        }
    }
}