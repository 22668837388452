.technical-task{
    position: relative;
    overflow: hidden;
    padding: 75px 0 60px;
    background: #012472;
    border-radius: 8px;
    @include _1200{
        padding: 50px 15px;
    }
    &__inner{
        max-width: 930px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }
    &__wrap{
        display: grid;
        grid-template-columns: 1fr 380px;
        position: relative;
        z-index: 1;
        @include _1200{
            max-width: 768px;
            margin: 0 auto;
        }
        @include _768{
            grid-template-columns: 1fr;
            grid-row-gap: 40px;
        }
    }
    &__title{
        margin: 0 0 40px;
        @include _1200{
            margin: 0 0 30px;
        }
        & > .page-title{
            color: #fff;
            text-align: center;
        }
    }
    &__list{
        padding: 35px 0;
        @include _1200{
            padding: 0;
        }
    }
    &__point{
        display: grid;
        grid-template-columns: 115px 1fr;
        align-items: center;
        @include _1200{
            grid-template-columns: 60px 1fr;
        }
        &-icon{
            width: 90px;
            height: 90px;
            background-color: #fff!important;
            border-radius: 50%;
            @include _1200{
                width: 50px;
                height: 50px;
            }
            svg{
                width: 35px;
                height: 35px;
                fill: $main-clr-alt;
                // fill: #ff0033;
                @include _1200{
                    width: 20px;
                    height: 20px;
                }
            }
        }
        &-text{
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            color: #FFFFFF;
            @include _1200{
                font-size: 15px;
                line-height: 18px;
            }
        }
        &:not(:last-child){
            margin: 0 0 50px;
            @include _1200{
                margin: 0 0 20px;
            }
        }
    }
    &__form{
        > .theme-policy{
            text-align: center;
        }
    }

    &::before,
    &::after{
        content: '';
        position: absolute;
        background-color: $main-clr;
        border-radius: 50%;
    }
    &::before{
        width: 360px;
        height: 360px;
        right: -180px;
        top: -180px;
        @include _768{
            right: -180px;
            top: -240px;
        }
    }
    &::after{
        width: 750px;
        height: 750px;
        left: -210px;
        bottom: -300px;
        @include _768{
            left: -510px;
            bottom: 0px;
        }
    }
}