.category-card{
    $this: &;
    display: block;
    position: relative;
    height: 330px;
    background-color: #fff;
    box-shadow: 3px 3px 24px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    padding: 25px 38px;
    transition: box-shadow $tr-time;
    &:hover{
        box-shadow: none;
        #{$this}__title{
            color: $main-clr;
        }
    }
    @include _1200{
        display: grid;
        grid-template-columns: 75px 1fr;
        padding: 20px 15px;
        height: auto;
    }
    &__name{
        @include _1200{
            order: 2;
        }
    }
    &__title{
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        color: #121212;
        display: block;
        margin: 0 0 10px;
        transition: color $tr-time;
        @include _1200{
            font-size: 18px;
            line-height: 24px;
            margin: 0;
        }
    }
    &__count{
        em{font-weight: 700;}
        font-size: 21px;
        line-height: 26px;
        color: #494949;
        @include _1200{
            font-size: 12px;
            line-height: 16px;
        }
    }
    &__image{
        position: absolute;
        bottom: 20px;
        right: 30px;
        // max-width: 320px;
        max-width:190px;
        max-height: 230px;
        object-fit: contain;
        @include _1200{
            position: static;
            width: 60px;
            height: 40px;
            object-fit: contain;
        }
    }
}