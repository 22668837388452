.advice-form{
    background-color: #00C1F5;
    border-radius: 8px;
    padding: 60px 0;
    position: relative;
    overflow: hidden;
    @include _1200{
        padding: 50px 15px;
    }
    &__container{
        max-width: 1455px;
        margin: 0 auto;
        padding-left: 240px;
        display: grid;
        grid-template-columns: 1fr 1.3fr;
        grid-column-gap: 50px;
        align-items: center;
        position: relative;
        z-index: 1;
        @include _1200{
            padding: 0;
        }
        @include _768{
            grid-template-columns: 1fr;
            grid-row-gap: 25px;
        }
    }
    &__text{
        @include _576{
            text-align: center;
        }
    }
    &__title{
        font-weight: 900;
        font-size: 48px;
        line-height: 52px;
        color: #FFFFFF;
        margin: 0 0 12px;
        @include _1200{
            font-size: 24px;
            line-height: 28px;
        }
    }
    &__desc{
        font-weight: 600;
        font-size: 21px;
        line-height: 26px;
        color: #FFFFFF;
        @include _1200{
            font-size: 15px;
            line-height: 18px;
        }
    }
    &__form{
        .theme-input{
            margin: 0;
        }
        @include _576{
            .theme-policy{
                text-align: center;
            }
        }
        &-grid{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 16px;
            grid-row-gap: 16px;
            @include _576{
                grid-template-columns: 1fr;
            }
        }
    }
    &__decor{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &-item{
            position: absolute;
            border-radius: 50%;
            &--1{
                width: 350px;
                height: 350px;
                background-color: $main-clr-alt; 
                top: -165px;
                left: -25px;
                @include _1200{
                    width: 195px;
                    height: 195px;
                    top: -110px;
                    left: unset;
                    right: -50px;
                }
            }
            &--2{
                width: 110px;
                height: 110px;
                border: 15px solid #fff;
                left: 170px;
                bottom: 70px;
                @include _1200{
                    border: 6px solid #fff;
                    width: 80px;
                    height: 80px;
                    bottom: unset;
                    left: calc(50% - 40px);
                    top: -40px;
                }
            }
            &--3{
                width: 170px;
                height: 170px;
                border: 15px solid #fff;
                right: -50px;
                top: -65px;
                @include _1200{
                    display: none;
                }
            }
        }
    }
}